@use "@angular/material" as mat;
@include mat.core();

$defaultBackground: #fafafa;

$woolworths-ocean: (
  50: #e6f4fd,
  100: #c0e0fb,
  200: #98ccfa,
  300: #70b8f8,
  400: #4ba6f7,
  500: #1971ed,
  600: #1665ca,
  700: #1359a7,
  800: #104d84,
  900: #0d4161,
  A100: #b5d5ff,
  A200: #82c1ff,
  A700: #499dff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
  )
);

$woolworths-midnight: (
  50: #e6e6eb,
  100: #c0c0c9,
  200: #9898a7,
  300: #707085,
  400: #4b4b63,
  500: #0e0d26,
  600: #0c0b23,
  700: #0a0920,
  800: #08071d,
  900: #06051a,
  A100: #6e6e7d,
  A200: #4d4d5c,
  A700: #2d2d3b,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
  )
);

h1 {
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  font-size: 38px;
  line-height: 42px;
  font-weight: 500;
}

h2 {
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  font-size: 28px;
  line-height: 32px;
  font-weight: 500;
}

h4 {
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
}

$wx-davinci-password-portal-fe-primary: mat.define-palette($woolworths-ocean);
$wx-davinci-password-portal-fe-accent: mat.define-palette($woolworths-midnight);
$wx-davinci-password-portal-fe-warn: mat.define-palette(mat.$red-palette);
$wx-davinci-password-portal-fe-theme: mat.define-light-theme(
  (
    color: (
      primary: $wx-davinci-password-portal-fe-primary,
      accent: $wx-davinci-password-portal-fe-accent,
      warn: $wx-davinci-password-portal-fe-warn,
    )
  )
);

@include mat.all-component-themes($wx-davinci-password-portal-fe-theme);

$primaryColor: mat.get-color-from-palette($wx-davinci-password-portal-fe-primary);
$accentColor: mat.get-color-from-palette($wx-davinci-password-portal-fe-accent);
$warnColor: mat.get-color-from-palette($wx-davinci-password-portal-fe-warn);

.primaryColor {
  color: $primaryColor
}

.accentColor {
  color: $accentColor
}

.warnColor {
  color: $warnColor
}

.link {
  color: inherit;
  font-weight: 500;
  &:hover {
    color: $primaryColor;
  }
}

html,
body {
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  height: 100%;
  margin: 0px;
  background: $defaultBackground;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.material-symbols-outlined {
  font-family: 'Material Symbols Outlined' !important;
}

.mat-mdc-snack-bar-container {
  &.error {
    --mdc-snackbar-container-color: #f44336;
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
  }
}
